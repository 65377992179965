<template>
  <div class="container">
    <headerTab />
    <router-view />
  </div>
</template>

<script>
import headerTab from '@c/serve/header'
export default {
  components: {
    headerTab,
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;

  box-sizing: border-box;
}
</style>