<template>
  <div class="header">
    <div class="content">
      <img
        class="logo"
        @click="toHome()"
        :src="require('@as/serve/logo.png')"
      />
      <el-menu
        default-active="1"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#000000"
        active-text-color="#A38F5D"
        :router="true"
      >
        <el-menu-item index="/serve">法律服务</el-menu-item>
        <el-submenu index="/serve/brand" :popper-append-to-body="false">
          <template slot="title">品牌服务</template>
          <el-menu-item index="/serve/brand">品牌服务</el-menu-item>
        </el-submenu>
        <el-menu-item index="/serve/knowledge">知识服务</el-menu-item>
        <el-menu-item index="/serve/strict">严选服务</el-menu-item>
        <el-menu-item index="/serve/tender">招标服务</el-menu-item>
      </el-menu>

      <el-image
        class="header_right_img"
        :src="require('@as/serve/right_img.png')"
        @click="toHome"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toHome() {
      this.$router.replace('/')
    },
    toServe() {
      this.$router.push({ path: '/serve' })
    },
    toBrand() {
      this.$router.push({ path: '/serve/brand' })
    },
    toKnowledge() {
      this.$router.push({ path: '/serve/knowledge' })
    },
    toStrict() {
      this.$router.push({ path: '/serve/strict' })
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  background: #000000;
  .content {
    width: 1200px;
    height: 70px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .logo {
      margin-right: 72px;
      width: 109px;
      height: 38px;
    }
    .header_right_img {
      margin-left: auto;
      width: 30px;
      height: 22px;
    }
    & /deep/ .el-menu-item:last-child.is-active {
      color: #1c9dbf !important;
    }
    & /deep/ .el-menu.el-menu--horizontal,
    & /deep/ .el-menu--horizontal > .el-menu-item.is-active,
    & /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
      border-bottom-color: transparent !important;
    }
    & /deep/ .el-menu--popup-bottom-start {
      margin: 0;
    }
    & /deep/ .el-submenu .el-menu-item,
    .el-menu--popup {
      width: 100px;
      min-width: 100px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      box-sizing: border-box;
    }
    & /deep/ .el-menu--popup {
      min-width: 100px;
    }
    & /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title,
    .el-menu--horizontal > .el-menu-item {
      color: #fff;
      &:hover {
        color: #a38f5d;
      }
    }
    & /deep/ .el-submenu__title i {
      display: none;
      color: #000;
    }
    & /deep/ .el-submenu .el-menu-item {
      color: #706340;
      &:hover {
        color: #fff;
      }
    }
  }
}
</style>